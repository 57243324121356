import * as React from "react"
import Layout from "../components/layout"
import { Container, Box, Heading, Text, Link, Flex, Button } from "../components/ui"
import ChevronRight from "../components/chevron-right"
import * as styles from "../components/404.css"

export default function DownloadFormsPage() {
  return (
    <Layout title="Download Forms">
      <Box paddingY={4}>
        <Container   style={{padding: '200px 0px'}}>
          <Flex variant="column">
           
            <Heading as="h1">Not a tech savy?</Heading>
            <Flex variant="column" gap={0}>
              <Text variant="lead" className={styles.text}>
                Download our fillable forms to create an account with us.<br/>
				Send them back to info@iconshipping.com and we'll get back to you.
              </Text>
			  <br />
              <Button to="https://cms-icon.logisoft.io/wp-content/uploads/2022/09/iconshipping-forms.zip" size="wide">
                Download Forms
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}
